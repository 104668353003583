<ae2-activity-list-container showInActivitiesPage="true" active="true">
    <ng-template #activityListTemplate let-results="results">
        <section class="activies" *ngIf="results.length > 0">
            <aside class="activies_header">
                <h1 class="activies_header_title">ATIVIDADES</h1>
                <hr>
                <p>Confira abaixo as atividades disponibilizadas por nossa equipe para você</p>
            </aside>

            <aside class="activies_container">

                <div class="activies_container_cads" fxLayout="row wrap" fxLayoutAlign="center center">
                    <div class="activies_container_cads_mobileWidth" *ngFor="let activity of results" fxFlex="32%" fxFlex.xs="auto" fxFlex.sm="49%">
                        <!-- <ae2-staff-edit [adminUrl]="activity.adminUrl">
                            <ng-template> -->
                                <mat-card class="activies_container_cads_card">

                                    <div class="activies_container_cads_card_image" *ngIf="activity.mediumImage" style.background-image="url({{ activity.mediumImage | ae2Thumbor:'400x'}})"></div>
                                    <div class="activies_container_cads_card_image" *ngIf="!activity.mediumImage" [ngStyle]="{'background': 'url(../assets/images/fallback.jpg)'}"></div>
                                    <!-- <img class="activies_container_cads_card_image" *ngIf="activity.mediumImage" src="{{activity.mediumImage | ae2Thumbor:'400x'}}"> -->
                                    <!-- <img class="activies_container_cads_card_image" *ngIf="!activity.mediumImage" src="/assets/images/fallback.jpg"> -->
                                    <mat-card-content class="activies_container_cads_card_content">
                                        <h1 class="activies_container_cads_card_content_title">{{activity.name}}</h1>
                                        <!-- <div class="activies_container_cads_card_content_description" [innerHTML]="replace(activity.description | ae2HTMLReplace)"></div> -->
                                        <div class="activies_container_cads_card_content_description">{{activity.tagline}}</div>
                                    </mat-card-content>
                                    <mat-card-content class="activies_container_cads_card_Color">
                                        <div class="activies_container_cads_card_Color_content">
                                            <h1 class="activies_container_cads_card_Color_content_title">{{activity.name}}</h1>
                                            <div class="activies_container_cads_card_Color_content_description" [innerHTML]="replace(activity.description | ae2HTMLReplace)"></div>
                                            <div fxLayoutAlign="end center">
                                                <button class="activies_container_cads_card_Color_content_button" mat-raised-button [routerLink]="['/atividades', activity.slug, activity.id]">Tudo sobre {{ activity.name }}</button>
                                            </div>
                                        </div>
                                    </mat-card-content>

                                </mat-card>
                            <!-- </ng-template>
                        </ae2-staff-edit> -->
                    </div>
                </div>


            </aside>

        </section>
    </ng-template>
</ae2-activity-list-container>