<ae2-unit-list-container>
  <ng-template #unitListTemplate let-results="results">
    <aside class="unit" *ngIf="results.length > 0">

      <div *ngFor="let item of results; let i = index">
        <div *ngIf="i == 0">
          <h2 class="title-unit">
            {{ item.name }}
          </h2> 
          <hr>
 
          <div class="unit_container" fxLayout fxLayout.xs="column" fxLayout.sm="column">
            <div class="unit_container_item" fxFlex="30%" fxFlex.xs="auto">
              <ae2-staff-edit [adminUrl]="item.adminUrl">
                <div class="unit_container_item_image" *ngIf="item.largeImage" style.background-image="url({{ item.largeImage | ae2Thumbor:'400x400'}})"></div>
                <div class="unit_container_item_image" *ngIf="!item.largeImage" [ngStyle]="{'background': 'url(/assets/images/fullback.jpg)'}"></div>
              </ae2-staff-edit>
            </div>
            <div class="unit_container_item" fxFlex="70%" fxFlex.xs="auto">
              <div class="unit_container_item_description" [innerHTML]="item.description"></div>
              <div class="unit_container_item_events">

                  <ng-template let-data>

                    <ae2-event-list-container [useLazyLoading]="true" [useLoadAllPages]="false" sort="start_time" pageSize="3" nextEvents="true" [unitList]="[item.id]">
                      <ng-template #eventListTemplate let-events="results" let-eventAutoBooks="eventAutoBooks" let-eventBooks="eventBooks">
                        
                        <h2 *ngIf="events.length > 0" class="unit_container_item_events_title">Próximos eventos em {{item.name}}</h2>
                        <div *ngIf="events.length > 0" fxLayout="row wrap" fxLayout.xs="column wrap">
                          <ng-container *ngFor="let event of events">

                            <ae2-event-widget 
                              [event]="event" 
                              [autoBooks]="eventAutoBooks" 
                              [eventBooks]="eventBooks" 
                              fxFlex="32%" fxFlex.xs="auto" fxFlex.sm="47%" 
                              class="unit_container_item_events_card">
                            </ae2-event-widget>
                            
                          </ng-container>
                        </div>
                        <div class="viewMore">
                          <button mat-raised-button color="accent" routerLink="/agenda">Ver Agenda Completa</button>
                        </div>

                      </ng-template>
                    </ae2-event-list-container>

                  </ng-template>

              </div>
            </div>
          </div>

          <div class="map" *ngIf="item.latitude && item.longitude">
            <div class="boxMap" fxLayout>
              <div class="boxMap_pin" fxFlex="20%"></div>
              <div class="boxMap_box" fxFlex="80%">
                <div class="boxMap_box_title">Onde estamos</div>
                <div class="boxMap_box_address" [innerHTML]="item.address"></div>
              </div>
            </div>
            <agm-map [latitude]="item.latitude" [longitude]="item.longitude" [zoom]="zoom" [styles]="customStyle" [scrollwheel]="false">
              <agm-marker [latitude]="item.latitude" [longitude]="item.longitude">
              </agm-marker>
            </agm-map>
          </div>

        </div>
      </div>

    </aside>
  </ng-template>
</ae2-unit-list-container>