import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-list-units',
    templateUrl: './list-units.component.html',
    styleUrls: ['./list-units.component.sass']
})
export class ListUnitsComponent implements OnInit {
    zoom: number = 16;
    customStyle: any = [
        {
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#d9e6c8'
            }
          ]
        },
        {
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#000000'
            }
          ]
        },
        {
          'elementType': 'labels.text.stroke',
          'stylers': [
            {
              'color': '#d9e6c8'
            }
          ]
        },
        {
          'featureType': 'administrative',
          'elementType': 'geometry.stroke',
          'stylers': [
            {
              'color': '#c9b2a6'
            }
          ]
        },
        {
          'featureType': 'administrative.land_parcel',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#6f75ff'
            }
          ]
        },
        {
          'featureType': 'administrative.land_parcel',
          'elementType': 'geometry.stroke',
          'stylers': [
            {
              'color': '#d9e6c8'
            }
          ]
        },
        {
          'featureType': 'administrative.land_parcel',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#d9e6c8'
            }
          ]
        },
        {
          'featureType': 'landscape.natural',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#d9e6c8'
            }
          ]
        },
        {
          'featureType': 'landscape.natural',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'color': '#d9e6c8'
            }
          ]
        },
        {
          'featureType': 'landscape.natural',
          'elementType': 'geometry.stroke',
          'stylers': [
            {
              'color': '#d9e6c8'
            }
          ]
        },
        {
          'featureType': 'landscape.natural.terrain',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#d9e6c8'
            }
          ]
        },
        {
          'featureType': 'landscape.natural.terrain',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'color': '#d9e6c8'
            }
          ]
        },
        {
          'featureType': 'landscape.natural.terrain',
          'elementType': 'geometry.stroke',
          'stylers': [
            {
              'color': '#d9e6c8'
            }
          ]
        },
        {
          'featureType': 'poi',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#d9e6c8'
            }
          ]
        },
        {
          'featureType': 'poi',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#93817c'
            }
          ]
        },
        {
          'featureType': 'poi.park',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'color': '#d9e6c8'
            }
          ]
        },
        {
          'featureType': 'poi.park',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#d9e6c8'
            }
          ]
        },
        {
          'featureType': 'road',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#f5f1e6'
            }
          ]
        },
        {
          'featureType': 'road.arterial',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#fafafa'
            }
          ]
        },
        {
          'featureType': 'road.highway',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#f8c967'
            }
          ]
        },
        {
          'featureType': 'road.highway',
          'elementType': 'geometry.stroke',
          'stylers': [
            {
              'color': '#fafafa'
            }
          ]
        },
        {
          'featureType': 'road.highway.controlled_access',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#e98d58'
            }
          ]
        },
        {
          'featureType': 'road.highway.controlled_access',
          'elementType': 'geometry.stroke',
          'stylers': [
            {
              'color': '#fafafa'
            }
          ]
        },
        {
          'featureType': 'road.local',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#fafafa'
            }
          ]
        },
        {
          'featureType': 'transit.line',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#fafafa'
            }
          ]
        },
        {
          'featureType': 'transit.line',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#fafafa'
            }
          ]
        },
        {
          'featureType': 'transit.line',
          'elementType': 'labels.text.stroke',
          'stylers': [
            {
              'color': '#fafafa'
            }
          ]
        },
        {
          'featureType': 'transit.station',
          'elementType': 'geometry',
          'stylers': [
            {
              'color': '#d9e6c8'
            }
          ]
        },
        {
          'featureType': 'water',
          'elementType': 'geometry.fill',
          'stylers': [
            {
              'color': '#b9d3c2'
            }
          ]
        },
        {
          'featureType': 'water',
          'elementType': 'labels.text.fill',
          'stylers': [
            {
              'color': '#92998d'
            }
          ]
        }
      ];
    ngOnInit(): void {
    }
}
