import { Component, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.sass']
})
export class ActivitiesComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer) {
  }
  ngOnInit(): void {
  }
  replace(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html.substr(0, 220) + (html.length > 220 ? '...' : ''));
  }
}
