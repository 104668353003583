import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { Ae2SiteSettingsModule } from '@angularecommerce/core/components/site-settings';
import { Ae2MarketingServiceModule } from '@angularecommerce/core/services/marketing';
import { Ae2ReplacePipeModule } from '@angularecommerce/core/pipes/replace';
import { MatButtonModule, MatListModule, MatIconModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    ReactiveFormsModule,
    Ae2SiteSettingsModule,
    Ae2ReplacePipeModule,
    Ae2MarketingServiceModule,
    MatButtonModule,
    MatListModule,
    MatIconModule
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule { }
