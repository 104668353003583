<ae2-instructor-list-container [active]="true" [showInInstructorsPage]="true">
    <ng-template #instructorListTemplate let-results="results">
        <section class="trainers" *ngIf="results.length > 0">
            <aside class="trainers_header">
                <h1 class="trainers_header_title">Instrutores</h1>
            </aside>

            <aside class="trainers_instructor">


                {{creatListInstructor(results)}}
                <div fxLayout="row wrap" fxLayoutAlign="center center">
                    <button class="trainers_instructor_button" [ngClass]="{'activeI': instrutor === item }" mat-button
                        *ngFor="let item of results" (click)="setInstructor(item)">
                        <div class="trainers_instructor_button_image" *ngIf="item.smallImage" style.background-image="url({{ item.smallImage | ae2Thumbor:'100x'}})"></div>
                        <div class="trainers_instructor_button_image" *ngIf="!item.smallImage" [ngStyle]="{'background': 'url(../assets/images/fullback.jpg)'}"></div>
                    </button>
                </div>


            </aside>

            <aside class="trainers_selectInstructor">
                <div class="instructor_container" fxLayout fxLayout.xs="column" fxLayout.sm="column" *ngIf="instrutor">
                    <div class="instructor_container_item" fxFlex="30%" fxFlex.xs="auto">
                        <div class="instructor_container_item_image" *ngIf="instrutor.largeImage"
                            style.background-image="url({{ instrutor.largeImage | ae2Thumbor:'700x'}})"></div>
                        <div class="instructor_container_item_image" *ngIf="!instrutor.largeImage" [ngStyle]="{'background': 'url(../assets/images/fullback.jpg)'}"></div>
                        <div class="instructor_container_item_social">
                            <mat-nav-list fxLayoutAlign="start center" fxLayoutAlign.xs="center center"
                                fxLayoutAlign.sm="center center">
                                <a matLine *ngIf="instrutor.socialTwitter" href="{{instrutor.socialTwitter}}" target="_blank"><i
                                        class="fa fa-twitter" aria-hidden="true"></i></a>
                                <a matLine *ngIf="instrutor.socialInstagram" href="{{instrutor.socialInstagram}}"
                                    target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                <a matLine *ngIf="instrutor.socialLinkedin" href="{{instrutor.socialLinkedin}}" target="_blank"><i
                                        class="fa fa-linkedin" aria-hidden="true"></i></a>
                                <a matLine *ngIf="instrutor.socialFacebook" href="{{instrutor.socialFacebook}}" target="_blank"><i
                                        class="fa fa-facebook" aria-hidden="true"></i></a>
                                <a matLine *ngIf="instrutor.socialSpotify" href="{{instrutor.socialSpotify}}" target="_blank"><i
                                        class="fa fa-spotify" aria-hidden="true"></i></a>
                                <a matLine *ngIf="instrutor.socialYoutube" href="{{instrutor.socialYoutube}}" target="_blank"><i
                                        class="fa fa-youtube-play" aria-hidden="true"></i></a>
                                <a matLine *ngIf="instrutor.socialVimeo" href="{{instrutor.socialVimeo}}" target="_blank"><i
                                        class="fa fa-vimeo" aria-hidden="true"></i></a>
                                <a matLine *ngIf="instrutor.socialFlickr" href="{{instrutor.socialFlickr}}" target="_blank"><i
                                        class="fa fa-flickr" aria-hidden="true"></i></a>
                                <a matLine *ngIf="instrutor.socialGooglePlus" href="{{instrutor.socialGooglePlus}}"
                                    target="_blank"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                                <a matLine *ngIf="instrutor.socialPinterest" href="{{instrutor.socialPinterest}}"
                                    target="_blank"><i class="fa fa-pinterest-p" aria-hidden="true"></i></a>
                            </mat-nav-list>
                        </div>
                    </div>
                    <div class="instructor_container_item" fxFlex="70%" fxFlex.xs="auto">
                        <div class="instructor_container_item_title">{{instrutor.nickname ? instrutor.nickname :
                            instrutor.firstName + ' ' + instrutor.lastName}}</div>
                        <div fxLayout="column" fxLayoutAlign="space-between center">
                            <div fxFlex.xs="auto" class="instructor_container_item_description" [innerHTML]="replace(instrutor.shortBio | ae2HTMLReplace)"></div>
                            <div fxFlex.xs="auto">
                                <button class="buttonMore" [routerLink]="['/instrutor', instrutor.slug, instrutor.id]"
                                    mat-raised-button>Mais sobre {{ instrutor.nickname || (instrutor.firstName + ' ' +
                                    instrutor.lastName) }}</button>
                            </div>
                        </div>
                        <div class="instructor_container_item_events">
                            <h2 class="instructor_container_item_events_title">Próximos eventos</h2>

                            <ae2-event-list-container [useLazyLoading]="true" [useLoadAllPages]="false" sort="start_time"
                                pageSize="3" nextEvents="true" [instructorList]="[instrutor.id]">
                                <ng-template let-events="results" let-books="eventBooks" let-autoBooks="eventAutoBooks">

                                    <div fxLayout="row wrap" fxLayout.xs="column wrap">
                                        <ng-container *ngFor="let event of events">

                                            <ae2-event-widget [event]="event" [autoBooks]="autoBooks"
                                                [eventBooks]="books" fxFlex="32%"
                                                fxFlex.xs="auto" fxFlex.sm="47%" class="instructor_container_item_events_card">
                                            </ae2-event-widget>

                                        </ng-container>
                                    </div>

                                </ng-template>
                            </ae2-event-list-container>

                        </div>
                    </div>
                </div>
            </aside>
        </section>
    </ng-template>
</ae2-instructor-list-container>