<section class="package">
    <aside class="package_header">
        <h1 class="package_header_title">Pacotes</h1>
        <p>Confira os planos disponíveis para treinar com a gente</p>
    </aside>
    <aside class="package_content">

        <ae2-package-list-container>
            <ng-template #packageListTemplate let-packages="results">

                <div fxLayout="row wrap" fxLayoutGap="5px" fxLayoutAlign="center center">
                    <ng-container *ngFor="let package of packages">
                        <div class="box">
                            <div class="box-faixa">
                                <ng-container *ngIf="package?.tags.indexOf('novidade') != -1">
                                    <div class="faixa">Novidade</div>
                                </ng-container>
                                <ng-container *ngIf="package?.tags.indexOf('lancamento') != -1">
                                    <div class="faixa">Lançamento</div>
                                </ng-container>
                                <ng-container *ngIf="package?.tags.indexOf('promocao') != -1">
                                    <div class="faixa">Promoção</div>
                                </ng-container>
                            </div>
                            <ae2-package-widget [data]="package"></ae2-package-widget>
                        </div>
                    </ng-container>
                </div>
            </ng-template>

        </ae2-package-list-container>

    </aside>
</section>