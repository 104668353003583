<footer class="footer" fxLayout="column" fxLayoutAlign="center center">
  <section class="footer_up">
    <button mat-fab (click)="goToTop()"><mat-icon>keyboard_arrow_up</mat-icon></button>
  </section>
  <ae2-site-settings class="footer_settings">
    <ng-template ae2SiteSettingsContent let-site="site">
      <section class="footer_botton">
        <div class="footer_botton_top" fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center">
          <div class="footer_botton_top_content" fxFlex="25%" fxLayout fxLayout.xs="column" fxLayoutAlign="center center">
            <div fxFlex="40%" fxFlex.xs="auto" class="footer_botton_top_content_logo"></div>
            <div fxFlex="60%" fxFlex.xs="auto" [innerHTML]="((site.description | ae2HTMLASCReplace) | slice:0:100) + (site.description.length > 100 ? '...' : '')"></div>
          </div>
          
          <div class="footer_botton_top_content" fxFlex="25%">
            <mat-nav-list>
              <a class="footer_botton_top_link" mat-list-item routerLink="/faq">FAQ</a>
              <a class="footer_botton_top_link" mat-list-item routerLink="/politica-de-privacidade">Política de Privacidade</a>
              <a class="footer_botton_top_link" mat-list-item target="_blank" href="/media/terms-and-conditions/termos-condicoes-eutreinodiferente.pdf">Termos e Condições</a>
              <!--<a class="footer_botton_top_link" mat-list-item routerLink="/depoimentos">Depoimentos</a>-->
            </mat-nav-list>
          </div>

          <div class="footer_botton_top_content" fxFlex="25%" fxLayoutAlign="center flex-start" fxLayout="column">
            <div class="newsletter_label">Assine nossa newsletter</div>
            <form class="footer_botton_top_content_form" [formGroup]="form" (submit)="submit($event)" fxLayout>
              <input fxFlex="75%" placeholder="Email" formControlName="email">
              <button fxFlex="25%" mat-raised-button [disabled]="form.pending || form.invalid"><mat-icon>email</mat-icon></button>
            </form>
          </div>
 
          <div class="footer_botton_top_content" fxFlex="25%" fxLayout="column" fxLayoutAlign="center start">
            <div fxFlex="100%" fxLayout fxLayoutAlign="start center">
              <h4>Siga-nos!</h4>
              <div>
                  
                    <div fxLayout>
                        <div class="footer_botton_top_content_social" matLine *ngIf="site.contactEmail" (click)="targetLint('mailto:'+site.contactEmail)"><i class="fa fa-envelope-o" aria-hidden="true"></i></div>
                        <div class="footer_botton_top_content_social" matLine *ngIf="site.socialTwitter" (click)="targetLint(site.socialTwitter)"><i class="fa fa-twitter" aria-hidden="true"></i></div>
                        <div class="footer_botton_top_content_social" matLine *ngIf="site.socialFacebook" (click)="targetLint(site.socialFacebook)"><i class="fa fa-facebook" aria-hidden="true"></i></div>
                        <div class="footer_botton_top_content_social" matLine *ngIf="site.socialInstagram" (click)="targetLint(site.socialInstagram)"><i class="fa fa-instagram" aria-hidden="true"></i></div>
                        <div class="footer_botton_top_content_social" matLine *ngIf="site.socialSpotify" (click)="targetLint(site.socialSpotify)"><i class="fa fa-spotify" aria-hidden="true"></i></div>
                        <div class="footer_botton_top_content_social" matLine *ngIf="site.socialYoutube" (click)="targetLint(site.socialYoutube)"><i class="fa fa-youtube" aria-hidden="true"></i></div>
                        <div class="footer_botton_top_content_social" matLine *ngIf="site.socialLinkedin" (click)="targetLint(site.socialLinkedin)"><i class="fa fa-linkedin" aria-hidden="true"></i></div>
                        <div class="footer_botton_top_content_social" matLine *ngIf="site.socialVimeo" (click)="targetLint(site.socialVimeo)"><i class="fa fa-vimeo" aria-hidden="true"></i></div>
                        <div class="footer_botton_top_content_social" matLine *ngIf="site.socialFlickr" (click)="targetLint(site.socialFlickr)"><i class="fa fa-flickr" aria-hidden="true"></i></div>
                    </div>
                    
              </div>
            </div>  
            <div fxFlex="100%" fxFlex.xs="auto" fxLayout fxLayoutAlign="center center">
              <div matLine>Pagamentos</div>
              <div matLine class="footer_botton_top_bandeiras"></div>
            </div>  
            
          </div>
        </div>
        <div fxLayout fxLayout.xs="column" fxLayoutAlign="space-between center">
          <div>Copyright © 2013-{{data}}. Todos os direitos reservados</div>
          <div><a href="https://angulare.app/?utm_source=cws&utm_campaign=eutreinodiferente" target="_blank" class="footer_botton_powered"> Powered by Angular e-Commerce </a></div>
        </div>
      </section>
  </ng-template>
</ae2-site-settings>
</footer>