import { NgModule } from '@angular/core';
import { CalendarHomeComponent } from './calendar-home.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    MatButtonModule
  ],
  declarations: [CalendarHomeComponent],
  exports: [CalendarHomeComponent]
})
export class CalendarHomeModule { }
