<section class="contact">
    <aside class="contact_header">
        <h1 class="contact_header_title">Fale conosco</h1>
    </aside>
    <aside class="contact_content">
        <ae2-contact-form code="contato" subject="Contado" [fields]="{
                name: { required: true },
                email: { required: true, validEmail: true },
                phone: { required: true },
                subject: { required: true},
                message: { required: true }
            }">

            <ng-template #contactFormTemplate let-form="form" let-methods="methods">
                <form [formGroup]="form" (submit)="methods.submit($event)">
                    <div class="contact_content_item">
                        <mat-form-field class="contact_content_item_input">
                            <input matInput placeholder="Nome" formControlName="name">
                        </mat-form-field>
                        <ae2-control-messages [control]="form.get('name')"></ae2-control-messages>
                    </div>

                    <div class="contact_content_item">
                        <mat-form-field class="contact_content_item_input">
                            <input matInput placeholder="Email" formControlName="email">
                        </mat-form-field>
                        <ae2-control-messages [control]="form.get('email')"></ae2-control-messages>
                    </div>

                    <div class="contact_content_item" fxLayout fxLayout.xs="column" fxLayoutAlign="space-between baseline">
                        <div class="contact_content_item_full" fxFlex="49%" fxFlex.xs="auto">
                            <mat-form-field class="contact_content_item_input">
                                <input matInput placeholder="Celular" ae2Mask="(99) 99999 9999" [specialCharacters]="true"
                                    formControlName="phone">
                            </mat-form-field>
                            <ae2-control-messages [control]="form.get('phone')"></ae2-control-messages>
                        </div>
                        <div class="contact_content_item_full" fxFlex="50%" fxFlex.xs="auto">
                            <mat-form-field class="contact_content_item_input">
                                <mat-select placeholder="Assunto" class="contact_content_item_select" formControlName="subject">
                                    <mat-option [value]="'Agendamentos'">Agendamentos</mat-option>
                                    <mat-option [value]="'Compras'">Compras</mat-option>
                                    <mat-option [value]="'Suporte'">Suporte</mat-option>
                                    <mat-option [value]="'Reclamações'">Reclamações</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <ae2-control-messages [control]="form.get('subject')"></ae2-control-messages>
                        </div>
                    </div>

                    <div class="contact_content_item">
                        <mat-form-field class="contact_content_item_text">
                            <textarea rows="5" matInput placeholder="Mensagem" formControlName="message"></textarea>
                        </mat-form-field>
                        <ae2-control-messages [control]="form.get('message')"></ae2-control-messages>
                    </div>

                    <div class="contact_content_item" fxLayout fxLayoutAlign="space-between center">
                        <button class="contact_content_item_button" type="submit" mat-raised-button [disabled]="form.invalid">ENVIAR</button>
                    </div>
                </form>
            </ng-template>
        </ae2-contact-form>

    </aside>
</section>