import { NgModule } from '@angular/core';
import { Ae2ScrollWatcherDirective } from './scrollwatcher.directive';
import { Ae2ScrollWatcherServiceModule } from '../../services/scroll-watcher/scroll-watcher.module';

@NgModule({
  imports: [Ae2ScrollWatcherServiceModule],
  declarations: [Ae2ScrollWatcherDirective],
  exports: [Ae2ScrollWatcherDirective]
})
export class Ae2ScrollWatcherModule { }
