/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./package.component.sass.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angularecommerce/core/components/package-widget/package-widget.component.ngfactory";
import * as i4 from "@angularecommerce/core/components/package-widget/package-widget.component";
import * as i5 from "@angularecommerce/core/services/dialog/dialog.service";
import * as i6 from "angular-l10n";
import * as i7 from "@angular/flex-layout/flex";
import * as i8 from "@angular/flex-layout/core";
import * as i9 from "@angular/cdk/bidi";
import * as i10 from "../../../../../node_modules/@angularecommerce/core/components/package-list-container/package-list-container.component.ngfactory";
import * as i11 from "@angularecommerce/core/classes/list-container-base/list-container-base";
import * as i12 from "@angularecommerce/core/components/package-list-container/package-list-container.component";
import * as i13 from "@angularecommerce/core/services/catalogue/catalogue.service";
import * as i14 from "./package.component";
var styles_PackageComponent = [i0.styles];
var RenderType_PackageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PackageComponent, data: {} });
export { RenderType_PackageComponent as RenderType_PackageComponent };
function View_PackageComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "faixa"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Novidade"]))], null, null); }
function View_PackageComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "faixa"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Lan\u00E7amento"]))], null, null); }
function View_PackageComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "faixa"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Promo\u00E7\u00E3o"]))], null, null); }
function View_PackageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "box-faixa"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, [[1, 2]], null, 1, null, View_PackageComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, [[1, 2]], null, 1, null, View_PackageComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, [[1, 2]], null, 1, null, View_PackageComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "ae2-package-widget", [], null, null, null, i3.View_Ae2PackageWidgetComponent_0, i3.RenderType_Ae2PackageWidgetComponent)), i1.ɵdid(10, 245760, null, 1, i4.Ae2PackageWidgetComponent, [i5.Ae2DialogService, i6.TranslationService], { data: [0, "data"] }, null), i1.ɵqud(335544320, 2, { templateRef: 0 })], function (_ck, _v) { var currVal_0 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.tags.indexOf("novidade")) != (0 - 1)); _ck(_v, 4, 0, currVal_0); var currVal_1 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.tags.indexOf("lancamento")) != (0 - 1)); _ck(_v, 6, 0, currVal_1); var currVal_2 = (((_v.context.$implicit == null) ? null : _v.context.$implicit.tags.indexOf("promocao")) != (0 - 1)); _ck(_v, 8, 0, currVal_2); var currVal_3 = _v.context.$implicit; _ck(_v, 10, 0, currVal_3); }, null); }
function View_PackageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["fxLayout", "row wrap"], ["fxLayoutAlign", "center center"], ["fxLayoutGap", "5px"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i7.LayoutDirective, [i8.MediaMonitor, i1.ElementRef, i8.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(2, 1785856, null, 0, i7.LayoutGapDirective, [i8.MediaMonitor, i1.ElementRef, [6, i7.LayoutDirective], i1.NgZone, i9.Directionality, i8.StyleUtils], { gap: [0, "gap"] }, null), i1.ɵdid(3, 737280, null, 0, i7.LayoutAlignDirective, [i8.MediaMonitor, i1.ElementRef, [6, i7.LayoutDirective], i8.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵand(16777216, [[1, 2]], null, 1, null, View_PackageComponent_2)), i1.ɵdid(5, 802816, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = "row wrap"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "5px"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "center center"; _ck(_v, 3, 0, currVal_2); var currVal_3 = _v.context.results; _ck(_v, 5, 0, currVal_3); }, null); }
export function View_PackageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "section", [["class", "package"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "aside", [["class", "package_header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "package_header_title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pacotes"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Confira os planos dispon\u00EDveis para treinar com a gente"])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "aside", [["class", "package_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "ae2-package-list-container", [], null, null, null, i10.View_Ae2PackageListContainerComponent_0, i10.RenderType_Ae2PackageListContainerComponent)), i1.ɵprd(6144, null, i11.Ae2ListContainerBase, null, [i12.Ae2PackageListContainerComponent]), i1.ɵdid(9, 4833280, null, 1, i12.Ae2PackageListContainerComponent, [i13.Ae2CatalogueService, i1.ChangeDetectorRef], null, null), i1.ɵqud(603979776, 1, { templateRef: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["packageListTemplate", 2]], null, 0, null, View_PackageComponent_1))], function (_ck, _v) { _ck(_v, 9, 0); }, null); }
export function View_PackageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-package", [], null, null, null, View_PackageComponent_0, RenderType_PackageComponent)), i1.ɵdid(1, 114688, null, 0, i14.PackageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PackageComponentNgFactory = i1.ɵccf("app-package", i14.PackageComponent, View_PackageComponent_Host_0, {}, {}, []);
export { PackageComponentNgFactory as PackageComponentNgFactory };
