import { NgModule } from '@angular/core';
import { ListUnitsComponent } from './list-units.component';
import { MatButtonModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AgmCoreModule } from '@agm/core';
import { Ae2EventWidgetModule } from '@angularecommerce/core/components/event-widget';
import { Ae2StaffEditModule } from '@angularecommerce/core/components/staff-edit';
import { Ae2UnitListContainerModule } from '@angularecommerce/core/components/unit-list-container';
import { Ae2EventListContainerModule } from '@angularecommerce/core/components/event-list-container';
import { Ae2ThumborModule } from '@angularecommerce/core/pipes/thumbor';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    RouterModule,
    Ae2StaffEditModule,
    Ae2UnitListContainerModule,
    Ae2EventWidgetModule,
    Ae2EventListContainerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDAHPM_R5i0i_kh_uMb-wtbKGEp-una0Ns'
    }),
    Ae2ThumborModule,
    MatButtonModule
  ],
  declarations: [ListUnitsComponent],
  exports: [ListUnitsComponent]
})
export class ListUnitsModule { }
