<ae2-carousel-container carouselCode="home" [config]="carouselConfig">
    <ng-template ae2CarouselOverlayContent 
    let-title="title" 
    let-description="description" 
    let-ctaLabel="ctaLabel" 
    let-ctaUrl="ctaUrl">
        <div class="ae2Carousel__overlay-content-default">
            <div class="ae2Carousel__overlay-content-default_bg">
                <h1 class="ae2Carousel__overlay-content-default__title" [innerHTML]="title"></h1>
                <h3 class="ae2Carousel__overlay-content-default__subtitle" [innerHTML]="description"></h3>
                <button class="ae2Carousel__overlay-content-default__button" mat-raised-button color="accent" [routerLink]="ctaUrl">{{ ctaLabel }}</button>
            </div>
        </div>
    </ng-template>
</ae2-carousel-container>

<ae2-article code="home-conceito">
    <ng-template ae2ArticleContent let-title="title" let-ctaLabel="ctaLabel" let-description="description">
        <div class="conceito_grid" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="conceito_grid_spacement" fxFlex="17%" fxHide.lt-sm="true" ></div>
            <div class="conceito_grid_content" fxFlex="60%" fxFlex.lt-sm="auto" [innerHtml]="description"></div>
            <div class="conceito_grid_spacement" fxFlex="17%" fxHide.lt-sm="true" ></div>
        </div>
    </ng-template>
</ae2-article>


<app-list-units id="estudio"></app-list-units>

<app-activities id="atividades"></app-activities>

<app-trainers id="instrutores"></app-trainers>

<app-package id="pacote"></app-package>

<app-contact id="contato"></app-contact>